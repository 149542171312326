<template>
  <b-card
    class="module-edit-wrapper"
  >
    <!-- form -->
    <b-form id="moduleForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="父ID"
            label-for="parent_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="parent_id"
              size="sm"
              v-model="module.parent_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="名称"
            label-for="module_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="module_name"
              size="sm"
              v-model="module.module_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="icon"
            label-for="module_icon"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="module_icon"
              size="sm"
              v-model="module.module_icon"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="URL"
            label-for="module_url"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="module_url"
              size="sm"
              v-model="module.module_url"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="参数"
            label-for="module_params"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="module_params"
              size="sm"
              v-model="module.module_params"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="是否菜单"
            label-for="in_menu"
            label-size="sm"
            class="mb-1"
          >
            <b-form-checkbox
                name="check-button"
                switch

                id="in_menu"
                v-model="module.in_menu"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="排序"
            label-for="position"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="position"
              size="sm"
              v-model="module.position"
            />
          </b-form-group>
        </b-col>
<!--        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="module.state"
            />
          </b-form-group>
        </b-col>-->

        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import moduleStore from './moduleStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      module: ref({}),
      selectOptions: [
        {value: '1', label: '111'},
        {value: '2', label: '222'},
        {value: '3', label: '333'},
      ],
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('module')) store.registerModule('module', moduleStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('module')) store.unregisterModule('module')
    })

    const edit = function() {
      store.dispatch('module/edit', {id: this.id}).then(res => {
        res.data.data['in_menu'] = res.data.data['in_menu'] == 1 ? true : false;
        this.module = res.data.data
      })
    }

    const view = function() {
      store.dispatch('module/view', {id: this.id}).then(res => {
        this.module = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      this.module['in_menu'] = this.module['in_menu'] ? 1 : 0;
      store.dispatch('module/save', this.module).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-module-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.custom-switch .custom-control-label::before{
  margin-top: 5px;
}
</style>
